<template>
  <v-container>
    <v-card
      outlined
      rounded="lg"
    >
      <v-toolbar flat>
        <v-toolbar-title>{{ upsellGroup.label }}</v-toolbar-title>
        <v-spacer />
        <div class="mr-2">
          <v-btn
            @click="toggleUpsellGroup"
            color="red"
            class="my-4"
            width="135px"
            elevation="0"
            outlined
          >
            {{ upsellGroup.status ? $t('disable') : $t('enable') }}
          </v-btn>
        </div>
        <div>
          <v-btn
            @click="showUpsellGroupForm"
            color="success"
            class="my-4"
            width="135px"
            elevation="0"
          >
            {{ $t('edit') }}
          </v-btn>
        </div>
      </v-toolbar>
    </v-card>
    <v-card
      outlined
      class="mt-4"
      rounded="lg"
    >
      <v-tabs v-model="tab">
        <v-tab href="#details">
          {{ $t('details') }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="details">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('description') }}</v-list-item-title>
                <v-list-item-subtitle v-if="upsellGroup.description">
                  {{ upsellGroup.description }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('category') }}</v-list-item-title>
                <v-list-item-subtitle v-if="upsellGroup.category">
                  {{ upsellGroup.category.name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>

                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('state') }}</v-list-item-title>
                <v-list-item-subtitle v-if="upsellGroup.status">
                  {{ $t('active') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{ $t('inactive') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('sortingWeight') }}</v-list-item-title>
                <v-list-item-subtitle v-if="upsellGroup.sortingWeight !== null">
                  {{ upsellGroup.sortingWeight }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>

                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('created') }}</v-list-item-title>
                <v-list-item-subtitle v-if="upsellGroup.created">
                  {{ formatDate(upsellGroup.created) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>

                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('changed') }}</v-list-item-title>
                <v-list-item-subtitle v-if="upsellGroup.changed">
                  {{ formatDate(upsellGroup.changed) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                    
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
	name: 'UpsellGroupProfile',
	props: {
		upsellGroup: {
			type: Object,
			required: true,
			default: () => ({})
		}
	},
	data() {
		return {
			tab: 'details',
		}
	},
	methods: {
		formatDate(timestamp) {
			if (!timestamp) return ''
			return new Date(timestamp).toLocaleDateString()
		},
		showUpsellGroupForm() {
			this.$store.commit('updateDataToMutate', {
				title: this.$t('updateUpsellGroup'),
				objectToMutate: this.upsellGroup,
				formComponent: 'components/upsell-group/UpsellGroupForm',
				saveCallback: function(dataToUpdate) {
					this.$store.dispatch('updateUpsellGroup', dataToUpdate).then(res => {
						if(res) {
							this.$store.commit('updateActionSuccess', {
								message: this.$t('success'),
								subMessage: this.$t('successfullyUpdatedUpsellGroup')
							})
						} else {
							this.$store.commit('updateActionError', {
								message: this.$t('error'),
								subMessage: this.$t('somethingWentWrong')
							})
						}
					}).catch(() => {
						this.$store.commit('updateActionError', {
							message: this.$t('error'),
							subMessage: this.$t('somethingWentWrong')
						})
					})
				}
			})
		},
		toggleUpsellGroup() {
			const newStatus = !this.upsellGroup.status
			this.$store.dispatch('updateUpsellGroup', {
				uuid: this.upsellGroup.uuid,
				status: newStatus
			}).then(res => {
				if(res) {
					this.$store.commit('updateActionSuccess', {
						message: this.$t('success'),
						subMessage: this.$t(newStatus ? 'successfullyEnabledUpsellGroup' : 'successfullyDisabledUpsellGroup')
					})
				} else {
					this.$store.commit('updateActionError', {
						message: this.$t('error'),
						subMessage: this.$t(newStatus ? 'couldNotEnableUpsellGroup' : 'couldNotDisableUpsellGroup')
					})
				}
			})
		}
	},
	watch: {
		upsellGroup(newValue) {
			this.forceRenderKey++
		}
	}
}
</script>
